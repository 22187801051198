
/* Imports */

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
/* Styling */

/*IDK what they do but they important*/

#LogoBigHalfCentered{
  margin-top: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-logo-small{
  height: 25vmin;
}

#af_flag{
  border-radius: 15px;
  width: 40vmin;
  border-color: black;
  border-style: solid;
  border-width: 5px;
}

/* GENERAL STUFF */

#division{
  font-family: "Work sans";
  margin: 30px;
  padding: 10px;
  font-weight: light;
  background-image: linear-gradient(to top left, rgb(50, 0, 0), rgb(125, 0, 0));
  color: white;
}

#code-info-division{
  font-family: 'Courier New', Courier, monospace;
  margin: 10px;
  padding: 1px;
  font-weight: bolder;
  background-color: rgb(0, 0, 0);
  border-radius: 15px;
  color: white;
}

#Background{
  width: 100%;
  height: 100%;
  position: fixed;
  background-image: linear-gradient(to top left, rgb(50, 0, 0), rgb(150, 0, 0));
  overflow-y: auto;
}

button{
  background-image: linear-gradient(to top left, rgb(50, 0, 0), rgb(125, 0, 0));
  color: white;
  border-style: none;
  font-weight: light;
}

.high-margin-relation{
  margin: 0;
}

.med-margin-relation{
  margin: 10px;
}


/* TITLES */

#title{
  font-family: "Libre Baskerville";
  margin-top: 10px;
  margin-bottom: 0;
}

#sub-title{
  font-family: "Work sans";
  margin-top: 5px;
  font-weight: 600;
}

/* LANDING PAGE/SINGLE PAGE CENTER */

#StartDiv{
  font-family: "Work sans";
  margin: 30px;
  font-weight: light;
  background-image: linear-gradient(to top left, rgb(50, 0, 0), rgb(125, 0, 0));
  color: white;
}

#external-link-button-start{
  background-image: linear-gradient(rgb(109, 0, 0),rgb(150, 0, 0));
  color: white;
  margin: 10px;
  padding: 10px 20px;
  margin-bottom: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 40vmin;
  font-size: xx-large;
  border-width: 2px;
}

/* LOGIN/USER RELATED */

@keyframes owner-animation {
  0%   {color: gold;}
  100% {color: yellow;}
}

@keyframes admin-animation {
  0%   {color: blue;}
  100% {color: lightblue;}
}

#owner-span{
  color: yellow;
  animation: owner-animation 1s ease-in-out infinite alternate;
  font-weight: bold;
}

#admin-span{
  color: blue;
  animation: admin-animation 1s ease-in-out infinite alternate;
  font-weight: bold;
}

#member-span{
  color: gray;
  font-weight: bold;
}

/* QUOTES ELEMENTS */

/**/